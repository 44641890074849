import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "ChatOps な稟議ワークフローシステムを開発しました  ",
  "date": "2020-12-25T09:00:58.000Z",
  "slug": "entry/2020/12/25/180058",
  "tags": ["medley"],
  "hero": "./2020_12_25.png",
  "heroAlt": "稟議システム"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`こんにちは。コーポレートエンジニアの溝口です。
メドレーでは、今年 7 月に稟議ワークフローシステムを導入しました。
詳細は「システム概要」の章でご紹介しますが、システムの全体像としては以下のようになっております。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201225/20201225155753.png",
      "alt": "20201225155753.png"
    }}></img>
    <p>{`ワークフローシステムと聞かれたら、どんなシステムを思い浮かべますか？
申請者がシステムで申請すると、予め定められた承認者へ承認依頼がメールで通知され、申請内容の確認及び承認のためにシステムへログインする、という流れがよくあるワークフローシステムではないかなと思います。`}</p>
    <p>{`我々はコーポレート部門として「徹底的に合理性を追求した組織基盤や、仕掛けづくりを行っていく」ことを目指しています。故に、メドレーの稟議ワークフローシステムにおいても`}<strong parentName="p">{`便利`}</strong>{`で`}<strong parentName="p">{`合理的`}</strong>{`なシステムを目指して開発を行いました。今回`}<strong parentName="p">{`ChatOps`}</strong>{`の概念を取り入れることで、一般的なワークフローシステムよりも洗練されたシステムを構築できたかなと思います。
本稿ではシステム概要及び、裏側の仕組みをご紹介していきます。
最後までお付き合いいただければ幸いです。`}</p>
    <h1>{`ChatOps とは`}</h1>
    <p>{`ChatOps とは「チャットサービス（Chat）をベースとして、システム運用（Ops）を行う」という意味です。ざっくり書くと「システムから Chat へメッセージを飛ばし、次のアクションが同じ Chat 画面で開始できる」というものとなります。(下記フローはあくまで一例です)`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201225/20201225155817.png",
      "alt": "20201225155817.png"
    }}></img>
    <p>{`ChatOps には以下のメリットがあると考えています。`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`常に立ち上げているツールという共通インターフェースである`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`インタラクティブなコミュニケーションにつながり、スピーディである`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`共有しやすく、記録に残しやすい`}</p>
      </li>
    </ol>
    <p>{`本稿では、詳しく説明はしませんが、興味がある方は事例等を解説しているサイトもあるので、是非探してみてください。`}</p>
    <h1>{`なぜ ChatOps なのか`}</h1>
    <p>{`稟議申請においては
承認者「これ値引きしてもらって ×× 円になったはずだけど、金額間違ってない？」
申請者「すいません、変更し忘れました。差戻しお願いします」
などのコミュニケーションが度々発生します。
通常のシステムであれば、確認事項がある際はシステム内のコミュニケーション機能を使う、もしくは、Chat に URL や稟議番号を転記して確認のためのコミュニケーションを取ることが想定されます。`}</p>
    <p>{`メドレー内の業務コミュニケーションは`}<strong parentName="p">{`Slack`}</strong>{`上で殆ど完結しています。
Slack ではない他の場所で会話が発生すると情報が分散しますし、Slack に URL を転記するといった行為や、別システムへのログインなども非効率です。
そこで、`}<strong parentName="p">{`共通インターフェースの Chat を中心にシステム構築する＝ ChatOps`}</strong>{`を採用し、稟議ワークフローを構築してみようと考えました。結果、稟議ワークフローシステムの情報を Slack へ連携し、稟議におけるコミュニケーションは Slack に集約、承認行為も Slack 上で可能、というシステムを構築することができました。`}</p>
    <h1>{`システム概要`}</h1>
    <h2>{`申請`}</h2>
    <p>{`申請者は`}<a parentName="p" {...{
        "href": "https://www.teamspirit.com/ja-jp/"
      }}>{`TeamSpirit`}</a>{`上で稟議内容を記入し、稟議申請を行います。 TeamSpirit とは、勤怠管理や工数管理、経費精算などを管理できるクラウドサービスです。Salesforce をプラットフォームとして採用しており、アイデア次第でいろいろなカスタマイズが可能です。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201225/20201225155854.png",
      "alt": "20201225155854.png"
    }}></img>
    <p>{`Slack から申請できるようにするのが ChatOps のあるべき姿かもしれませんが、過去の申請からコピーしたい、申請種別ごとに入力する項目が異なる等の要件を考慮し、TeamSpirit から申請するように設計しました。申請の導線については、今後もよりよい仕組みに磨き上げていきたいと考えています。`}</p>
    <h2>{`承認`}</h2>
    <p>{`申請者が「稟議申請」ボタンを押下すると、Slack の稟議チャンネルに申請内容及び添付ファイルが自動投稿されます。
承認者は申請内容に問題がなければ、投稿に配置されているボタンを利用して承認・差戻しが行えます。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201225/20201225155917.png",
      "alt": "20201225155917.png"
    }}></img>
    <p>{`承認者は稟議ワークフローシステムへアクセスすることなく、Slack で承認行為が完結できます。稟議内容において確認事項がある場合には Slack の投稿スレッドで申請者と質疑応答のやり取りができ、承認・差戻しの判断に必要なコミュニケーションが行えます。`}</p>
    <h2>{`後続のアクション`}</h2>
    <p>{`承認後には、
・申請者に承認 or 差戻し結果を Slack の DM(ダイレクトメッセージ)で通知する`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201225/20201225160246.png",
      "alt": "20201225160246.png"
    }}></img>
    <br clear="all" />
    <ul>
      <li parentName="ul">{`後続の担当者へ Slack で通知する`}</li>
      <li parentName="ul">{`(法務押印などの)承認後タスクを作成し担当者に通知する
等、後続のアクションへつながっていく仕組みも用意しました。`}</li>
    </ul>
    <h1>{`システムの裏側`}</h1>
    <h2>{`入力インターフェース`}</h2>
    <p>{`入力画面は、TeamSpirit で標準提供されている`}<a parentName="p" {...{
        "href": "https://www.teamspirit.com/ja-jp/blog/entry/post-31.html"
      }}>{`稟議オブジェクト`}</a>{`を利用しました。入力項目は標準で用意されているコンポーネントを利用し、メドレー独自で定義しています。承認プロセスを定義すれば、Slack を使わずに TeamSpirit のみでも運用は可能です。`}</p>
    <h2>{`Slack 通知`}</h2>
    <p>{`Salesforce の標準機能と`}<a parentName="p" {...{
        "href": "https://developer.salesforce.com/docs/atlas.ja-jp.apexcode.meta/apexcode/apex_intro_what_is_apex.htm"
      }}>{`Apex`}</a>{` を用いた Script 処理を使って Slack 通知をしています。`}</p>
    <blockquote>
      <p parentName="blockquote">{`Apex とは、Salesforce 内で利用するビジネスロジック用のオブジェクト指向のプログラミング言語(ほぼ Java)のことです。`}</p>
    </blockquote>
    <p>{`Slack 通知までの大きな流れは以下です。`}</p>
    <ol>
      <li parentName="ol">{`稟議申請ボタンを押したタイミングでステータス項目を「未申請」から「申請中」へ変更`}</li>
      <li parentName="ol">{`プロセスビルダーにてステータス項目が「申請中」になったことを検知して Apex をコール`}</li>
      <li parentName="ol">{`Apex 内で申請情報や承認者情報の取得`}</li>
      <li parentName="ol">{`Slack API をコールし、Slack へ投稿`}</li>
    </ol>
    <p>{`1~4 のプロセスを詳しく見ていきます。`}</p>
    <h3>{`1. 稟議申請ボタンを押したタイミングでステータス項目を「未申請」から「申請中」へ変更`}</h3>
    <p>{`申請者が「稟議申請」ボタンを押したタイミングで承認プロセスを走らせます。
申請時のアクションとして、 ステータス「申請中」とします。ステータスが変わる毎に処理を走らせているので、ステータス定義は一つ肝になります。`}</p>
    <h3>{`2. プロセスビルダーにてステータス項目が「申請中」になったことを検知して Apex をコール`}</h3>
    <p>{`プロセスビルダーを利用することで「稟議レコードを作成または編集したとき」に何らかの処理を実施することが可能です。今回は、ステータスが「申請中」になった場合に Apex をコールする、という処理にしています。`}</p>
    <h3>{`3. Apex 内で申請情報や承認者情報の取得`}</h3>
    <p>{`通知に必要な情報を揃えるため、Apex の処理では稟議オブジェクトの申請情報と合わせて次の承認者情報も取得しています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "apex"
    }}><pre parentName="div" {...{
        "className": "language-apex"
      }}><code parentName="pre" {...{
          "className": "language-apex"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`String`}</span>{` ownerId `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` p`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`OwnerId`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//申請者のユーザ名を取得`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`String`}</span>{` applicant `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token sql language-sql"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="span" {...{
              "className": "token keyword"
            }}>{`SELECT`}</span>{` Username `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`FROM`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`User`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`WHERE`}</span>{` Id `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` : ownerId`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Username`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//承認プロセスのレコード取得`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`String`}</span>{` processInstanceId `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token sql language-sql"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="span" {...{
              "className": "token keyword"
            }}>{`SELECT`}</span>{` Id `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`FROM`}</span>{` ProcessInstance `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`WHERE`}</span>{` TargetObjectId `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` : p`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Id `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`ORDER`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`BY`}</span>{` CreatedDate `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`DESC`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`limit`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`1`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//承認者の ID を取得`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`String`}</span>{` approveId `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token sql language-sql"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="span" {...{
              "className": "token keyword"
            }}>{`SELECT`}</span>{` OriginalActorId `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`FROM`}</span>{` ProcessInstanceWorkitem `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`WHERE`}</span>{` ProcessInstanceId `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` : processInstanceId`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`OriginalActorId`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h3>{`4. Slack API をコールし、Slack へ投稿`}</h3>
    <p>{`Apex が取得した情報をもとに、Slack に投稿します。
稟議内容を記載し、申請者・承認者に対してメンションされるようにユーザ名も記載します。
また、今回は承認者用にインタラクティブボタンを配置する必要があったので、`}<a parentName="p" {...{
        "href": "https://api.slack.com/block-kit"
      }}>{`Block Kit`}</a>{`を利用し、ボタン付きメッセージを作成しました。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"text"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"hoge"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"blocks"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"section"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"text"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"mrkdwn"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"text"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"fuga"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    ...
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"actions"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"elements"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"button"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"text"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"plain_text"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"text"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"承認"`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"value"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Approve"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"style"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"primary"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"button"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"text"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"plain_text"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"text"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"差戻し"`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"value"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Reject"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"style"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"danger"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`TeamSpirit(Salesforce)→Slack への投稿は開発において苦労したポイントの一つです。`}</p>
    <h2>{`Slack からのアクション`}</h2>
    <p>{`Slack の投稿に埋め込んでいるボタンがクリックされた際は、Lambda を経由して TeamSpirit(Salesforce)の RestAPI をコールし、承認処理を実行しています。
また承認後は、ボタンを「承認」スタンプに置き換えています。`}</p>
    <h1>{`開発を終えて`}</h1>
    <p>{`稟議ワークフローシステムを導入するにあたり、ChatOps の概念を取り入れ Slack に連携する業務システムを構築しました。
承認者からは「Slack で承認やコメントができ、社外からでもすぐに対応できるので便利」「Salesforce-Slack 連携は他にも活用できるので是非やっていこう」などのコメントをいただきました。また、承認後にもスレッドにて、「振込お願いします」「物品届きました」等のやりとりも行っており、情報が Slack に集約されていく狙い通りの運用になったかと思っています。`}</p>
    <p>{`Chat サービスを利用している会社では、今回ご紹介した ChatOps は業務効率化するにあたり、有効な手法になるのではないでしょうか。もちろん、すべて Chat に連携すればよいというものでもなく、しっかり設計や運用検討を行う必要があります。
今後は ChatOps に限らず業務効率化につながるものはどんどんやっていきたいと考えています。`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`メドレーのコーポレート部門では「徹底的に合理性を追求した組織基盤や、仕掛けづくりを行っていく」ことを目指して、業務効率改善のための開発を推進しています。面白そう！と感じた方、メドレーでどんどん改善してみたい！と思っていただけた方は、ぜひ弊社採用ページからご応募お願いします！`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>
    <p>{`最後まで読んでいただきありがとうございました。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      